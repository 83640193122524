
import PermitType from '../../types/permit.type';
import {IPermitRequest} from '../../interface/permitRequest.interface';
import { IFileDataBase64 } from '../../interface/fileDataBase64.interface';

const initFileData: IFileDataBase64 = {
    content: '',
    name: '',
    size: 0,
    contentType: '-1'
}

export const permitRequestData: IPermitRequest = {
    projectId: -1,
    permitRequestId: -1,
    paymentAmount: 0,
    agreeTakanon: 0,
    agreeToVolunteer: false,
    agreeDataTransfer: false,
    permitType: PermitType.REGULAR,
    hasColorAndType: true,
    hasAdditionalAddress: 0,
    personDetails: {

        familyName: '',
        privateName: '',
        id: '',
        scanId: initFileData,
        hasAdditionalPersonIdFile: false,
        additionalPersonIdFile: initFileData,
    },
    carDetails: {
        vehicleGroup: -1,
        carId: '',
        vColor: -1,
        vType: -1,
        scanCarId: initFileData,
        scanWorkStatment: initFileData,
        ownershipCarType: 1,
    },
    contactDetails: {

        phone: '',
        mobile: '',
        house: '',
        appartment: '',
        streetId: '-1',
        email: '',
    },
    additionalContactDetails: {

        mailRashumNum: '',
        mailCity: '',
        mailHouse: '',
        mailMikod: '',
        mailStreet: '',
        mailAppartment: '',
    },
    craditCard: {

        cardNumber: '',
        cardExpireDateMM: '',
        cardExpireDateYY: '',
        cardAddedNumber: '',
        cardHolderId: '',
        cardHolderName: '',
    },
    extraFiles: {
        isRentApp: 0,
        hasBusiness: 0,
        extraFile1: initFileData,
        extraFile2: initFileData,
        extraFile3: initFileData,
        extraFile4: initFileData,
        extraFile5: initFileData,
    },
    extra1: '',
    extra2: '',
    extra3: '',
    extra4: {},
    extra5: '',
}

export const permitRequestDummyData = {
    projectId: -1,
    permitRequestId: -1,
    asmachta: -1,
    amountPayed: 0,
    ownershipCarType: 2,
    agreeTakanon: 1,
    carId: '5245882',
    vColor: 100,
    vType: 100,
    scanCarId: '',
    scanWorkStatment: null,
    agreeToVolunteer: false,
    agreeDataTransfer: false,
    permitType: 0,
    familyName: 'dummy',
    privateName: 'data',
    id: '123456782',
    scanId: '',
    phone: '',
    mobile: '0507480014',
    house: '12',
    appartment: '',
    streetId: '-1',
    email: 'a@test.com',
    additionalAddress: 0,
    mailRashumNum: '',
    mailCity: '',
    mailHouse: '',
    mailMikod: '',
    mailStreet: '',
    mailAppartment: '',
    cardNumber: '',
    cardExpireDateMM: '',
    cardExpireDateYY: '',
    cardAddedNumber: '',
    cardHolderId: '',
    extraFile1: null,
    extraFile2: null,
    extraFile3: null,
    extraFile4: null,
    extraFile5: null,
    isRentApp: 0,
    hasBusiness: 0,
}
import React from 'react';
import { CheckBoxColors } from '../../../types/colors/checkBoxColors.type';
import style from '../form.module.css';

type Props = {
    value: number | string,
    id: string,
    name: string,
    onClick: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void,
    onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void,
    label: string,
    isChecked: boolean,
    color?: CheckBoxColors
}

const RadioBtn = ({ value, id, name, onClick, onBlur, label, isChecked, color }: Props) => {

    const radioBoxInputClasses = `${style.radioBoxInput} ${getRadioInputClass(color)}`;

    return (
        <label htmlFor={id} className={style.radioBoxLabel}>
            <input
                checked={isChecked}
                value={value}
                onBlur={onBlur}
                id={id}
                name={name}
                className={radioBoxInputClasses}
                type='radio'
                autoComplete="off"
                onClick={(e) => onClick(e)}
            />
            {label}
        </label>
    );
}

function getRadioInputClass(color?: CheckBoxColors) {

    if(!color) {
        return style.radioBoxInputPrimary;
    }

    if(color === CheckBoxColors.SECONDARY) {
        return style.radioBoxInputSecondary;
    } else if(color === CheckBoxColors.DANGER) {
        return style.radioBoxInputDanger;
    }else if(color === CheckBoxColors.INFO) {
        return style.radioBoxInputInfo;
    }else if(color === CheckBoxColors.LIGHT) {
        return style.radioBoxInputLight;
    }else if(color === CheckBoxColors.PRIMARY) {
        return style.radioBoxInputPrimary;
    }else if(color === CheckBoxColors.SUCCESS) {
        return style.radioBoxInputSuccess;
    }else if(color === CheckBoxColors.WARNING) {
        return style.radioBoxInputWarning;
    }

}

export default RadioBtn;
import React from 'react';
import { Routes, Route } from 'react-router-dom'
import { HomePageProvider } from './contexts/HomePageContext';
import { PermitRequestProvider } from './contexts/PermitRequestContext';
import NotFound from './pages/NotFound';
import Footer from './components/UI/page/Footer';
import Style from './App.module.css';
import Page from './components/UI/page/Page';
import Header from './components/UI/page/Header';

function App() {


  return (
    <div className={Style.App}>
      <Header />
      <Page>
        <Routes>
          <Route path="/" element={<NotFound />} />
          <Route path='/home/:projectId' element={<HomePageProvider />} />
          <Route path="/permit-request/:projectId" element={<PermitRequestProvider />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </Page>
      <Footer />
    </div>
  );
}

export default App;

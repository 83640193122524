import React from 'react';
import SectionTitle from '../SectionTitle';
import SelectInput from '../../../../components/form/select/SelectInput';
import InputGroup from '../../../../components/form/InputGroup';
import { usePermitRequestContext } from '../../../../contexts/PermitRequestContext';
import FormSection from './FormSection';
import { monthsSelectOption, yearsSelectOption } from '../../../../data/craditCardForm.data';

function CreditCardFormSection() {

    const { handleChange, Formik } = usePermitRequestContext();

    const { errors, values, touched, handleBlur } = Formik;
    const payment = values.paymentAmount;
    const craditCardDetails = values.craditCard;
    const craditCardErrors = errors.craditCard ? errors.craditCard : {};
    const craditCardTouched = touched.craditCard ? touched.craditCard : {};

    const hasPayment = payment > 0 ? true : false;
    const title = `פרטי התשלום(₪${payment})`;

    if (!hasPayment) {
        return null;
    }

    return (
        <FormSection>
            <SectionTitle title={title} />

            <div className='row'>
                <div className='col-12 col-xs-12 col-md-4 col-lg-3'>
                    <InputGroup
                        name={'craditCard.cardNumber'}
                        label={'מספר כרטיס אשראי'}
                        value={craditCardDetails.cardNumber}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        isRequired={true}
                        errorMsg={(craditCardErrors.cardNumber && craditCardTouched.cardNumber) ? craditCardErrors.cardNumber : undefined}
                    />
                </div>
                <div className='col-4 col-xs-4 col-md-3 col-lg-2'>
                    <SelectInput
                        name={'craditCard.cardExpireDateYY'}
                        label={'תוקף שנה'}
                        value={craditCardDetails.cardExpireDateYY}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        selects={yearsSelectOption}
                        required={true}
                        errorMsg={(craditCardErrors.cardExpireDateYY && craditCardTouched.cardExpireDateYY) ? craditCardErrors.cardExpireDateYY : undefined}
                    />
                </div>
                <div className='col-4 col-xs-4 col-md-3 col-lg-2'>
                    <SelectInput
                        name={'craditCard.cardExpireDateMM'}
                        label={'תוקף חודש'}
                        value={craditCardDetails.cardExpireDateMM}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        selects={monthsSelectOption}
                        required={true}
                        errorMsg={(craditCardErrors.cardExpireDateMM && craditCardTouched.cardExpireDateMM) ? craditCardErrors.cardExpireDateMM : undefined}
                    />
                </div>
                <div className='col-4 col-xs-4 col-md-2 col-lg-2 col-lg-1'>
                    <InputGroup
                        name={'craditCard.cardAddedNumber'}
                        label={'CVV'}
                        value={craditCardDetails.cardAddedNumber}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        isRequired={true}
                        errorMsg={(craditCardErrors.cardAddedNumber && craditCardTouched.cardAddedNumber) ? craditCardErrors.cardAddedNumber : undefined}
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col-12 col-sm-12 col-md-3 col-lg-3'>
                    <InputGroup
                        name={'craditCard.cardHolderId'}
                        label={'תעודת זהות של בעל הכרטיס'}
                        value={craditCardDetails.cardHolderId}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        isRequired={true}
                        errorMsg={(craditCardErrors.cardHolderId && craditCardTouched.cardHolderId) ? craditCardErrors.cardHolderId : undefined}
                    />
                </div>
                <div className='col-12 col-xs-12 col-md-4 col-lg-3'>
                    <InputGroup
                        name={'craditCard.cardHolderName'}
                        label={'שם על גבי הכרטיס(באנגלית)'}
                        value={craditCardDetails.cardHolderName}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        isRequired={true}
                        errorMsg={(craditCardErrors.cardHolderName && craditCardTouched.cardHolderName) ? craditCardErrors.cardHolderName : undefined}
                    />
                </div>
            </div>
        </FormSection>
    );
}

CreditCardFormSection.defaultProps = {
    isChecked: false
}

export default CreditCardFormSection;
import React, { FC } from 'react';
import translateHtmlUncodeToChar from '../../../helpers/language/TranslateHtmlUncodeToChar';
import { useProjectConfigContext } from '../../../contexts/ProjectConfigContext';
import SelectWithSearch from './SelectWithSearch';
import { ISelectOption } from '../../../interface/form.interface';

type TypeSelectCarColor = {
    name: string,
    value: number,
    onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void,
    onChange?: (name: string | number, value: string | number) => void,
    errorMsg?: string | undefined,
}

const SelectCarColor: FC<TypeSelectCarColor> = ({
    name,
    value,
    onBlur = (e) => { },
    onChange = (e) => { },
    errorMsg = '',
}) => {

    const { projectConfigState } = useProjectConfigContext();
    const colorList = projectConfigState.colors;

    let colors: ISelectOption[] = [{ value: -1, label: 'אין אופציות' }];

    if(colorList !== undefined) {

        colors = colorList.map(color => {
    
            return { label: translateHtmlUncodeToChar(color.name), value: color.id }
        });
    }

    const valueStr = colors.find(option => option.value === value);

    return (
        <SelectWithSearch
            errorMsg={errorMsg}
            name={name}
            label={'צבע רכב'}
            value={valueStr ? valueStr : null}
            isRequired={true}
            selects={colors}
            handleBlur={onBlur}
            handleChange={onChange} />
    );
}

export default SelectCarColor;
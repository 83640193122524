import React, { FC, ReactNode } from 'react';
import Style from './layout.module.css';

type TypePage = {
    children: ReactNode
}

const Page: FC<TypePage> = ({ children }) => {

    let classes = 'container ' + Style.page;

    return (
        <div className={classes}>
            {children}
        </div>
    );
    
}

export default Page;
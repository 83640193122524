import { IMonthsSelectOption, IYearsSelectOption } from "../interface/config/creditCardSelects.interface";

export const yearsSelectOption: IYearsSelectOption[] = [
    { value: '-1', name: 'בחר' },
    { value: '24', name: '2024' },
    { value: '25', name: '2025' },
    { value: '26', name: '2026' },
    { value: '27', name: '2027' },
    { value: '28', name: '2028' },
    { value: '29', name: '2029' },
    { value: '30', name: '2030' },
    { value: '31', name: '2031' },
    { value: '32', name: '2032' },
    { value: '33', name: '2033' },
    { value: '34', name: '2034' },
];

export const monthsSelectOption: IMonthsSelectOption[] = [
    { value: '-1', name: 'בחר' },
    { value: '01', name: '01' },
    { value: '02', name: '02' },
    { value: '03', name: '03' },
    { value: '04', name: '04' },
    { value: '05', name: '05' },
    { value: '06', name: '06' },
    { value: '07', name: '07' },
    { value: '08', name: '08' },
    { value: '09', name: '09' },
    { value: '10', name: '10' },
    { value: '11', name: '11' },
    { value: '12', name: '12' }
];
import React from 'react';
import { useNavigate } from "react-router-dom";
import style from '../homePage.module.css';
import ActionBox from './ActionBox';
import { IoCreateOutline } from "react-icons/io5";
import { AiFillFileAdd } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import { TbCalendarTime } from "react-icons/tb";
import { TbFileSearch } from "react-icons/tb";
import { useProjectConfigContext } from '../../../contexts/ProjectConfigContext';
import { useHomePageContext } from '../../../contexts/HomePageContext';
import { ActionPortalTypes } from '../../../types/actionPortal.type';


const ActionBoxContainer = () => {

    const navigate = useNavigate();
    const { openOtpModal } = useHomePageContext();
    const { projectConfigState } = useProjectConfigContext();
    const projectId = projectConfigState.projectId;
    const portalConfig =  projectConfigState.portalConfig;
    const addPermitRequestTitle = 'הגשת בקשה לתו חניה';
    const addFileToRequestTitle = 'הוספת קובץ לבקשה ';
    const checkStatusTitle = 'בדיקת סטטוס בקשה';
    const cancelPtermitTitle = 'ביטול תו קיים';
    const checkPermitValidityTitle = 'בדיקת תוקף תו קיים';

    return (
        <div className={style.actionBoxContainer}>
            <ActionBox
                onClick={() => navigateTo(`/permit-request/${projectId}`)}
                title={addPermitRequestTitle}
                icon={<IoCreateOutline />} />
            <ActionBox
                onClick={() => openOtpModal(addFileToRequestTitle, ActionPortalTypes.ADD_FILE_TO_REQUEST)}
                title={addFileToRequestTitle} icon={<AiFillFileAdd />}
            />
            <ActionBox
                onClick={() => openOtpModal(checkStatusTitle, ActionPortalTypes.CHECK_REQUEST_STATUS)}
                title={checkStatusTitle} icon={<TbFileSearch />}
            />
            {portalConfig.hasCancelPermitOption ? (
                <ActionBox
                    onClick={() => openOtpModal(cancelPtermitTitle, ActionPortalTypes.CANCEL_PERMIT)}
                    title={cancelPtermitTitle} icon={<MdDelete />}
                />
            ): ''}
            <ActionBox
                onClick={() => openOtpModal(checkPermitValidityTitle, ActionPortalTypes.CHECK_PEMIT_VALIDITY)}
                title={checkPermitValidityTitle} icon={<TbCalendarTime />}
            />
        </div>
    );

    function navigateTo(url) {

        navigate(url);
    }
}

export default ActionBoxContainer
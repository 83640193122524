import React from 'react';
import translateHtmlUncodeToChar from '../../../helpers/language/TranslateHtmlUncodeToChar';
import SelectWithSearch from './SelectWithSearch';
import { IExtra4Map } from '../../../interface/permitRequest.interface';
import { IStreets } from '../../../interface/config/IConfig.interface';
import { ISelectOption } from '../../../interface/form.interface';

type Props = {
    extra4Map: IExtra4Map,
    name: string,
    handleBlur: (e: any) => void,
    handleChange: (name: string | number, value: string | number) => void,
    errorMsg?: string | undefined,
    value: number,
    streets: IStreets[],
    isRequired: boolean,
    disabled: boolean
}

const SelectStreet = ({ name, handleBlur, handleChange, errorMsg, value, streets, isRequired, disabled }: Props) => {

    let streetsOp: ISelectOption[] = [{ value: -1, label: 'אין אופציות' }];

    if (streets !== undefined || streets !== null || streets.length > 0) {

        streetsOp = streets.map(street => {
            return { label: translateHtmlUncodeToChar(street.name), value: street.id }
        });
    }

    //@ts-ignore
    const valueStr = streetsOp.find(option => option.value === value);

    return (
        <SelectWithSearch
            name={name}
            label={'רחוב'}
            selects={streetsOp}
            disabled={disabled}
            isRequired={isRequired}
            handleBlur={handleBlur}
            handleChange={handleChange}
            value={valueStr ? valueStr : null}
            errorMsg={errorMsg}

        />
    );
}

export default SelectStreet; 
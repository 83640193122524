import { IStreets } from "../../interface/config/IConfig.interface";

const listOfStrretCodeForMunicipalParking = [9001, 9003, 9004, 9005];

export function filterStreetsForPardesHannaKarkur(street: IStreets[], newExtra4: {[x: string]: string}): IStreets[] {

    let needParkingInMunicipalPardesHannaKarkur = newExtra4.needParkingInMunicipalPardesHannaKarkur;
    let isCityResident = newExtra4.isCityResident;
    let agriculturalSchoolFaculty = newExtra4.agriculturalSchoolFaculty;
    let collectionOrWaterDepartmentEmployees = newExtra4.collectionOrWaterDepartmentEmployees;
    let planningAndConstructionCommitteeEmployees = newExtra4.planningAndConstructionCommitteeEmployees;
    let kindergartenElaAndAgosEmployees = newExtra4.kindergartenElaAndAgosEmployees;

    let filteredStreets = [...street];

    if(needParkingInMunicipalPardesHannaKarkur === '1' && isCityResident === '1') {
    
        filteredStreets = filteredStreets.filter(option => listOfStrretCodeForMunicipalParking.includes(option.id));
    } else if(needParkingInMunicipalPardesHannaKarkur === '1' && isCityResident === '0') {

        filteredStreets = filteredStreets.filter(option => option.id === 9002);
    }

    // AgriculturalSchoolFaculty
    if(agriculturalSchoolFaculty === '1' && isCityResident === '0') {
    
        filteredStreets = filteredStreets.filter(option => option.id === 9007);
    }

    if(agriculturalSchoolFaculty === '1' && isCityResident === '1') {
    
        filteredStreets = filteredStreets.filter(option => option.id === 9006);
    }
    
    // CollectionOrWaterDepartmentEmployees
    if(collectionOrWaterDepartmentEmployees === '1' && isCityResident === '0') {
    
        filteredStreets = filteredStreets.filter(option => option.id === 9009);
    }

    if(collectionOrWaterDepartmentEmployees === '1' && isCityResident === '1') {
    
        filteredStreets = filteredStreets.filter(option => option.id === 9008);
    }

    // PlanningAndConstructionCommitteeEmployees
    if(planningAndConstructionCommitteeEmployees === '1' && isCityResident === '0') {
    
        filteredStreets = filteredStreets.filter(option => option.id === 9011);
    }

    if(planningAndConstructionCommitteeEmployees === '1' && isCityResident === '1') {
    
        filteredStreets = filteredStreets.filter(option => option.id === 9010);
    }

    // KindergartenElaAndAgosEmployees
    if(kindergartenElaAndAgosEmployees === '1' && isCityResident === '0') {
    
        filteredStreets = filteredStreets.filter(option => option.id === 9013);
    }

    if(kindergartenElaAndAgosEmployees === '1' && isCityResident === '1') {
    
        filteredStreets = filteredStreets.filter(option => option.id === 9012);
    }


    console.log(`newExtra4: ${JSON.stringify(newExtra4)}`);
    console.log(`filteredStreets: ${JSON.stringify(filteredStreets)}`);
    filteredStreets = [{ id: -1, name: 'צריך לבחור' }, ...filteredStreets];

    return filteredStreets;
}
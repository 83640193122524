import { IModalOtp } from "../interface/modalOtp.interface";
import { ActionPortalTypes } from "../types/actionPortal.type";
import { OtpStatus } from "../types/otpStatus.type";

export enum OtpModalActionKind {
    CLOSE_MODAL = 'closeModal',
    OPEN_MODAL = 'openModal',
    OTP_SENT_TO_MOBILE = 'otpSentToMobile',
    OTP_RESET = 'otpReset',
    OTP_SUCCESS = 'otpSuccess',
    OTP_SUCCESS_FILE = 'otpSuccessFile',
}

type ComponentInfoAction = {
    type: OtpModalActionKind.OPEN_MODAL,
    payload: {
        modalTitle: string,
        portalAction: ActionPortalTypes,
    }
} | {
    type: OtpModalActionKind.CLOSE_MODAL
} | {
    type: OtpModalActionKind.OTP_SENT_TO_MOBILE
} | {
    type: OtpModalActionKind.OTP_RESET
} | {
    type: OtpModalActionKind.OTP_SUCCESS,
    payload: {
        name: string,
        value: string,
    }
} | {
    type: OtpModalActionKind.OTP_SUCCESS_FILE
};

//
export function otpModalReduser(state: IModalOtp, action: ComponentInfoAction) {

    switch (action.type) {
        case OtpModalActionKind.CLOSE_MODAL:
            return {...state, isOtpModalOpen: false, modalTitle: ''};
        case OtpModalActionKind.OPEN_MODAL:
            return {...state, isOtpModalOpen: true, modalTitle: action.payload.modalTitle, portalAction: action.payload.portalAction};
        case OtpModalActionKind.OTP_SENT_TO_MOBILE:
            return {...state, otpStatus: OtpStatus.OTP_SENT_TO_MOBILE};
        case OtpModalActionKind.OTP_RESET:
            return {...state, otpStatus: OtpStatus.PERMIT_OR_REQUEST_DETAILS};
        case OtpModalActionKind.OTP_SUCCESS:
            return {...state, otpStatus: OtpStatus.OTP_SUCCESS, [action.payload.name]: action.payload.value};
        case OtpModalActionKind.OTP_SUCCESS_FILE:
            return {...state, otpStatus: OtpStatus.OTP_SUCCESS};
        default:
            return state;
    }
}
import React, { CSSProperties } from 'react';
import Page from '../components/UI/page/Page';

const titleStyle: CSSProperties = {
    fontSize: "3rem",
}

const ErrorStyle: CSSProperties = {
    fontSize: "5rem",
}

const containerStyle: CSSProperties = {
    paddingTop: "5rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
}

const NotFound = () => {


    return (
        <Page>
            <div style={containerStyle}>
                <div style={titleStyle}>דף לא קיים</div>
                <div style={ErrorStyle}>404</div>
            </div>
        </Page>
    );
}

export default NotFound;
import * as yup from 'yup';
import { MAX_FILE_SIZE, MAX_FILE_SIZE_MESSAGE, ONLY_LETTERS_HEBREW_OR_ENGLISH_PERMITED_MESSAGE, REQUIRED_FIELD_MESSAGE, SUPPORTED_FORMATS, TYPE_FILE_MESSAGE } from "../errorMessages";
import validatorPersonId from '../../helpers/validates/validatorPersonId';

export const yupObjectPersonDetails = {
    privateName: yup.string()
        .required(REQUIRED_FIELD_MESSAGE)
        .matches(/^[\u0590-\u05FFa-zA-Z\s]+$/, ONLY_LETTERS_HEBREW_OR_ENGLISH_PERMITED_MESSAGE),
    familyName: yup.string()
        .required(REQUIRED_FIELD_MESSAGE)
        .matches(/^[\u0590-\u05FFa-zA-Z\s]+$/, ONLY_LETTERS_HEBREW_OR_ENGLISH_PERMITED_MESSAGE),
    id: yup.string()
        .required(REQUIRED_FIELD_MESSAGE)
        .test('valid-id', 'מספר ת.ז אינו חוקי. צריך להכיל ספרת ביקורת', value => validatorPersonId(value)),
    scanId: yup.mixed()
        .nonNullable()
        .required(REQUIRED_FIELD_MESSAGE)
        .test(
            "FILE_SIZE",
            MAX_FILE_SIZE_MESSAGE,
            (value) => {

                return !value || (value && value.size <= MAX_FILE_SIZE)
            })
        .test(
            "FILE_FORMAT",
            TYPE_FILE_MESSAGE,
            (value) => {

                return !value || (value && SUPPORTED_FORMATS.includes(value?.contentType))
            }
        ),
    additionalPersonIdFile: yup.mixed()
        .when("hasAdditionalPersonIdFile", (hasAdditionalPersonIdFile, schema) => {

            if (Array.isArray(hasAdditionalPersonIdFile) && hasAdditionalPersonIdFile.length > 0) {
                const hasAddiPerFileBool = hasAdditionalPersonIdFile[0];

                if (!hasAddiPerFileBool) {
                    return schema.notRequired();
                }
            }

            return (
                schema
                    .nonNullable()
                    .required(REQUIRED_FIELD_MESSAGE)
                    .test(
                        "FILE_SIZE",
                        MAX_FILE_SIZE_MESSAGE,
                        (value) => {
       
                            return !value || (value && value.size <= MAX_FILE_SIZE)
                        }
                    )
                    .test(
                        "FILE_FORMAT",
                        TYPE_FILE_MESSAGE,
                        (value) => {

                            return !value || (value && SUPPORTED_FORMATS.includes(value?.contentType))
                        }
                    )
            );
        }),
}
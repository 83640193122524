import React from 'react';
import Style from './layout.module.css';


const Footer = () => {

    var dateobj = new Date();
        
    const year = dateobj.getFullYear();

    return(
        <footer className={Style.footerDiv}>
            <span className={Style.name}>כל הזכויות שמורות ללולהטק בע"מ © {year}</span>
            <img className={Style.logo} src={require('../../../assets/images/lolatech logo.png')} alt='Lolatech Logo' />
        </footer>
    );
}

export default Footer;
import React, { createContext, ReactNode, useContext, useEffect, useReducer, useState } from 'react';
import { initProjectConfigData } from '../data/initialize/projectConfig.data';
import { axiosPrivate } from '../api/api';
import ResponseStatus from '../types/responseStatus.type';
import { translateHtmlUnicodeToChar } from '../helpers/language/TranslateHtmlUncodeToChar';
import translateErrorCode from '../helpers/errors/translateErrorCode';
import { useNavigate } from 'react-router-dom';
import { PROJECT_CONFIG_ACTION, projectConfigReducer } from '../reducers/ProjectConfigReducer';
import { IAuth, IColor, IMarker, IProjectConfig, IStreets, IVehicleGroups } from '../interface/config/IConfig.interface';
import { AuthActionKind, authReducer } from '../reducers/AuthReducer';

const ONE_MINUTE = 1000 * 60;
const TWENTY_MINUTES = 1000 * 60 * 20;
const ONE_HOUR = 1000 * 60 * 60;

interface ProjectConfigContextProps {
    projectConfigState: IProjectConfig;
    projectConfigDispatch: React.Dispatch<IProjectConfig>;
    authState: IAuth;
    isProjectConfigLoading: boolean;
    timeSpentOnPage: number;
    setProjectId: (projectId: number) => void;
    loadProjectConfig: () => Promise<void>;
}

export const ProjectConfigContext = createContext<ProjectConfigContextProps | undefined>(undefined);

interface ProjectConfigProviderProps {
    children: ReactNode;
}

export const ProjectConfigProvider = ({ children }: ProjectConfigProviderProps) => {

    const navigate = useNavigate();

    const [projectConfigState, projectConfigDispatch] = useReducer(projectConfigReducer, initProjectConfigData);
    const [authState, authDispatch] = useReducer(authReducer, { token: '', loggin: false });
    const [isProjectConfigLoading, setIsProjectConfigLoading] = useState(false);
    const [timeSpentOnPage, setTimeSpentOnPage] = useState(0); // in milliseconds

    function setToken(token: string) {

        authDispatch({
            type: AuthActionKind.SET_TOKEN,
            token: token, 
            loggin: true 
        });
    }

    function setProjectId(projectId: number) {

        projectConfigDispatch({
            type: PROJECT_CONFIG_ACTION.SET_PROJECT_ID,
            payload: { projectId: projectId }
        });
    }

    async function loadProjectConfig() {

        let message = '';
        if (projectConfigState.projectId === -1) return;

        setIsProjectConfigLoading(true);
        try {

            const token = await getToken();

            const responseStatus = await getProjectConfigDetails(token);
        } catch (error) {

            console.log('Error', error);
            if (error.response) {

                message = translateErrorCode(error);
                console.log(`Error - ${message}`);
            } else if (error.request) {
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }

            navigate('notFound');
        }

        setIsProjectConfigLoading(false);
    }

    async function getProjectConfigDetails(token: string) {

        const response = await axiosPrivate.get('/systemTables/portal-project-config', {
            headers: { Authorization: `Bearer ${token}` }
        });

        const data = response.data;

        if (!data?.status || !data?.data || !data.data?.portalDataConfigBean) {
            throw new Error("no data from server.");
        }

        if (data.status === ResponseStatus.FAILURE) {

            throw new Error("failure message - " + (data?.message) ? data.message : '');
        }

        const portalDataConfigBean = data.data.portalDataConfigBean;
        const projectConfig = portalDataConfigBean.projectConfig;
        const portalConfig = projectConfig.portalConfig;
        let hasAdditionalPersonIdFile = false;

        if (portalDataConfigBean?.makers) {

            portalDataConfigBean.makers = portalDataConfigBean.makers.sort((a: IMarker, b: IMarker) => a.name.localeCompare(b.name));
        }

        if (portalDataConfigBean?.colors) {

            portalDataConfigBean.colors = portalDataConfigBean.colors.sort((a: IColor, b: IColor) => a.name.localeCompare(b.name));
        }

        if (portalDataConfigBean?.streets) {

            portalDataConfigBean.streets = portalDataConfigBean.streets.sort((a: IStreets, b: IStreets) => a.name.localeCompare(b.name));
        }

        if (portalDataConfigBean?.vehicleGroups) {

            portalDataConfigBean.vehicleGroups = portalDataConfigBean.vehicleGroups.sort((a: IVehicleGroups, b: IVehicleGroups) => a.vehicleGroupName.localeCompare(b.vehicleGroupName));
        }

        if (projectConfig.hebName) {
            projectConfig.hebName = translateHtmlUnicodeToChar(projectConfig.hebName);
        }

        if(projectConfig.projectId === 26 || projectConfig.projectId === 1) {
            hasAdditionalPersonIdFile = true;
        }

        projectConfigDispatch({
            type: PROJECT_CONFIG_ACTION.SET_DATA,
            payload: { data: portalDataConfigBean, hasAdditionalPersonIdFile: hasAdditionalPersonIdFile }
        })

        return 'success';
    }

    async function getToken() {

        const data = { projectId: projectConfigState.projectId };
        let token = '';

        try {

            const response = await axiosPrivate.post('/citizenSticker/citizen-login', data);

            if (response?.data?.data?.accessToken) {
                token = response.data.data.accessToken;
            }

            setToken(token);
            console.log(token)
            return token;
        } catch (error) {

            console.log(`getToken error ${JSON.stringify(error)}`);
            return '';
        }
    }

    async function refreshToken() {

        const token = getToken();

        if(!token) {
            navigate('notFound');
        }
    }

    useEffect(() => {

        loadProjectConfig();
    }, [projectConfigState.projectId]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeSpentOnPage(prevTime => prevTime + ONE_MINUTE); // increment by 1 second
        }, ONE_MINUTE); // every 1 second
        return () => clearInterval(intervalId); // cleanup
    }, []); // run only once on mount

    useEffect(() => {

        if(timeSpentOnPage === 0) {
            return;
        }

        if (timeSpentOnPage % TWENTY_MINUTES === 0) {
            refreshToken();
        }

        if(timeSpentOnPage > (ONE_HOUR + TWENTY_MINUTES)) {
            navigate('notFound');
        }

    }, [timeSpentOnPage]);

    return (
        <ProjectConfigContext.Provider value={{
            projectConfigState, projectConfigDispatch,
            setProjectId, loadProjectConfig, authState, isProjectConfigLoading, timeSpentOnPage
        }}>
            {children}
        </ProjectConfigContext.Provider>
    );
}

export const useProjectConfigContext = () => useContext(ProjectConfigContext);
import { IComponentInfo } from "../interface/componentInfo.interface";
import { AlertColors } from "../types/colors/alertColors.type";

export enum ComponentInfoActionKind {
    SET_ALERT = 'SET_ALERT',
    LOADING = 'LOADING',
    NOT_LOADING = 'NOT_LOADING',
    REMOVE_ALERT = 'REMOVE_ALERT',
}

type ComponentInfoAction = {
    type: ComponentInfoActionKind.SET_ALERT;
    payload: {
        hasAlert: boolean,
        message: string,
        alertColor: AlertColors,
    }
} | {
    type: ComponentInfoActionKind.LOADING;
} | {
    type: ComponentInfoActionKind.NOT_LOADING;
} | {
    type: ComponentInfoActionKind.REMOVE_ALERT;
};

export function componentInfoReducer(state: IComponentInfo, action: ComponentInfoAction) {

    switch (action.type) {
        case ComponentInfoActionKind.SET_ALERT:
            
            const alertColor = action.payload.alertColor;
            
            return {
                ...state,
                hasAlert: action.payload.hasAlert,
                message: action.payload.message,
                alertColor: alertColor,
                isLoading: false,
            }
        case ComponentInfoActionKind.LOADING:

            return {
                ...state,
                hasAlert: false,
                message: '',
                alertColor: AlertColors.PRIMARY,
                isLoading: true,
            };
        case ComponentInfoActionKind.NOT_LOADING:

            return {
                ...state,
                isLoading: false,
            };
        case ComponentInfoActionKind.REMOVE_ALERT:

            return {
                ...state,
                hasAlert: false,
                message: '',
                alertColor: AlertColors.PRIMARY,
            };
        default:
            return state;
    }
} 
import React, { FC } from 'react';
import { IoMdClose } from 'react-icons/io';
import Style from './alers.module.css';

type TypeCloseBtn = {
    onClick: () => void
}

const CloseBtn: FC<TypeCloseBtn> = ({ onClick }) => {

    return (
        <span className={Style.btnCloseAlet} onClick={onClick}>
            <IoMdClose className={Style.btnCloseAletIcon} />
        </span>
    );
}

export default CloseBtn;
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PermitRquestForm from './components/form/PermitRequestForm';
import TopDeclaration from './components/TopDeclaration';
import { usePermitRequestContext } from '../../contexts/PermitRequestContext';
import { useProjectConfigContext } from '../../contexts/ProjectConfigContext';
import LoadingModal from '../../components/loadingModal/LoadingModal';
import DangerAlert from '../../components/Alerts/DangerAlert';
import SuccessModal from './components/successModal/SuccessModal';
import PageTitle from './components/PageTitle';
import SendReqConfirmModal from './components/SendReqConfirmModal';

const PermitRequestPage = () => {

    const { setProjectId } = useProjectConfigContext();
    const { componentInfo } = usePermitRequestContext();

    const params = useParams();
    const projectId = Number(params.projectId);

    useEffect(() => {

        setProjectId(projectId);
    }, [projectId]);

    return (
        <>
            <SuccessModal />
            <SendReqConfirmModal />
            {componentInfo.isLoading && <LoadingModal />}
            <DangerAlert isShow={componentInfo.hasAlert} message={componentInfo.message} subject='בקשה לא נשלחה' />
            <PageTitle />
            <TopDeclaration />
            <PermitRquestForm />
        </>
    );
}

export default PermitRequestPage;
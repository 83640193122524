import React, { FC } from 'react';
import translateHtmlUncodeToChar from '../../../helpers/language/TranslateHtmlUncodeToChar';
import { useProjectConfigContext } from '../../../contexts/ProjectConfigContext';
import SelectWithSearch from './SelectWithSearch';
import { ISelectOption } from '../../../interface/form.interface';

type Props = {
    name: string,
    value: number,
    onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void,
    onChange?: (name: string | number, value: string | number) => void,
    errorMsg?: string | undefined,
    required?: boolean,
    disabled?: boolean
}

const SelectVehicleGroup: FC<Props> = ({
    name,
    value,
    onBlur = (e) => { },
    onChange = (e) => { },
    errorMsg = '',
    required = false,
    disabled = false,
}) => {

    const { projectConfigState } = useProjectConfigContext();
    const vehicleGroupList = projectConfigState.vehicleGroups;

    let vehicleGroups: ISelectOption[] = vehicleGroupList.map(vehicleGroup => {

        return { label: translateHtmlUncodeToChar(vehicleGroup.vehicleGroupName), value: vehicleGroup.id }
    });

    const valueStr = vehicleGroups.find((option: ISelectOption) => option.value === value);

    return (
        <SelectWithSearch
            errorMsg={errorMsg}
            name={name}
            label={'סוג רכב'}
            value={valueStr ? valueStr : null}
            isRequired={required}
            selects={vehicleGroups}
            handleBlur={onBlur}
            handleChange={onChange}
            disabled={disabled}
        />
    );
}

export default SelectVehicleGroup;
import React from 'react';
import { usePermitRequestContext } from '../../../../contexts/PermitRequestContext';
import SelectStreet from '../../../../components/form/select/SelectStreet';
import InputGroup from '../../../../components/form/InputGroup';
import SectionTitle from '../SectionTitle';
import FormSection from './FormSection';
import CheckBoxesForPardesHannaKarkur from '../../../../features/checkBoxesForPardesHannaKarkur/CheckBoxesForPardesHannaKarkur';

function ContactDetailsFormSection() {

    const { handleChange, Formik, filteredStreets, handleExtra4MapChange, houseAndAppDes, streetDes } = usePermitRequestContext();

    const { errors, values, touched, handleBlur, setFieldValue, setFieldTouched } = Formik;
    const contactDetails = values.contactDetails;
    const contaDetailsErrors = errors.contactDetails ? errors.contactDetails : {};
    const contaDetailsTouched = touched.contactDetails ? touched.contactDetails : {};

    return (
        <FormSection>
            <SectionTitle title='פרטי הכתובת' />

            <CheckBoxesForPardesHannaKarkur handleChange={handleExtra4MapChange} extra4Map={values.extra4} />

            <div className='row align-items-end'>
                <div className='col-6 col-md-6 col-lg-4'>
                    <SelectStreet
                        name={'streetId'}
                        value={Number(contactDetails.streetId)}
                        isRequired={true}
                        disabled={streetDes}
                        extra4Map={values.extra4}
                        handleChange={(name, newValue) => setFieldValue('contactDetails.streetId', newValue)}
                        streets={filteredStreets}
                        handleBlur={e => {
                            handleBlur(e);
                            setFieldTouched('contactDetails.streetId', true);
                        }}
                        errorMsg={(contaDetailsErrors.streetId && contaDetailsTouched.streetId) ? contaDetailsErrors.streetId : undefined} />
                </div>

                <div className='col-3 col-md-3 col-lg-2'>
                    <InputGroup
                        name={'contactDetails.house'}
                        label={'בית'}
                        value={contactDetails.house}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        disabled={houseAndAppDes}
                        isRequired={true}
                        errorMsg={(contaDetailsErrors
                            .house && contaDetailsTouched.house) ? contaDetailsErrors.house : undefined}
                    />
                </div>
                <div className='col-3 col-md-3 col-lg-2'>
                    <InputGroup
                        name={'contactDetails.appartment'}
                        label={'דירה'}
                        value={contactDetails.appartment}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        disabled={houseAndAppDes}
                        errorMsg={(contaDetailsErrors
                            .appartment && contaDetailsTouched.appartment) ? contaDetailsErrors
                            .appartment : undefined}
                    />

                </div>

            </div>

            <div className='row align-items-end'>
                <div className='col-md-6 col-lg-4'>
                    <InputGroup
                        name={'contactDetails.email'}
                        label={'דואר אלקטרוני'}
                        value={contactDetails.email}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        isRequired={true}
                        errorMsg={(contaDetailsErrors
                            .email && contaDetailsTouched.email) ? contaDetailsErrors
                            .email : undefined}
                    />

                </div>
            </div>

            <div className='row align-items-end'>
                <div className='col-6 col-lg-3'>
                    <InputGroup
                        name={'contactDetails.mobile'}
                        label={'טלפון נייד'}
                        value={contactDetails.mobile}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        isRequired={true}
                        errorMsg={(contaDetailsErrors
                            .mobile && contaDetailsTouched.mobile) ? contaDetailsErrors
                            .mobile : undefined} />

                </div>
                <div className='col-6 col-lg-3'>
                    <InputGroup
                        name={'contactDetails.phone'}
                        label={'טלפון נוסף'}
                        value={contactDetails.phone}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        errorMsg={(contaDetailsErrors
                            .phone && contaDetailsTouched.phone) ? contaDetailsErrors
                            .phone : undefined}
                    />

                </div>
            </div>
        </FormSection>
    );

}

ContactDetailsFormSection.defaultProps = {
    isChecked: false
}

export default ContactDetailsFormSection;
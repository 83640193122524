import React, { FC, ReactNode } from 'react';
import RedStar from '../RedStar';

type TypeSelectInput = {
    name: string,
    label: string,
    selects: any[],
    value: string | number,
    onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void,
    onBlur?: (e: React.FocusEvent<any>) => void,
    required?: boolean,
    errorMsg?: string | ReactNode,
}

const SelectInput: FC<TypeSelectInput> = ({
    name,
    label,
    selects,
    value,
    onChange = (event) => { },
    onBlur = (event) => { },
    required = false,
    errorMsg = '',
}) => {

    let classes = 'form-select ';
    const options = selects.map((option, index) => {

        return <option key={index} value={option.value}>{option.name}</option>;
    });

    const hasError = errorMsg ? true : false;

    if (hasError) {
        classes += ' is-invalid';
    }
    return (
        <>

            <label className="form-label" htmlFor={`${name}id`}>{label}</label>
            {required && <RedStar />}

            <select
                className={classes}
                id={`${name}id`}
                name={name}
                value={value}
                onChange={onChange}
                required={required}
                onBlur={onBlur}
                aria-label="Default select example">
                {options}
            </select>
            {hasError ? <div id='asas' className="invalid-feedback"> {errorMsg} </div> : ''}
        </>
    )
}

export default SelectInput;
import React, { FC, ReactNode } from 'react';
import ReactSelect from 'react-select';
import RedStar from '../RedStar';
import { ISelectOption } from '../../../interface/form.interface';

export interface IReactSelectOption {
    value: number,
    label: string,
    options: any
} 

type TypeSelectWithSearch = {
    name: string,
    label: string,
    value: ISelectOption,
    selects: ISelectOption[],
    handleChange: (name: string | number, value: string | number) => void,
    handleBlur: (e: React.FocusEvent<HTMLInputElement, Element>) => void,
    errorMsg?: string | ReactNode | undefined,
    isRequired?: boolean,
    disabled?: boolean,
}

const SelectWithSearch: FC<TypeSelectWithSearch> = ({
    name, label, value, selects, handleChange,
    errorMsg = '',
    handleBlur = undefined,
    disabled = false,
    isRequired = false
}) => {

    let classes = '';
    const placeholder = 'צריך לבחור';

    const hasError = errorMsg ? true : false;

    if (hasError) {
        classes = ' is-invalid';
    }

    return (
        <>
            <label className="form-label" htmlFor={`${name}id`}>
                {label}
                {isRequired && <RedStar />}
            </label>
            <ReactSelect
                placeholder={placeholder}
                className={classes}
                isRtl={true}
                options={selects}
                onChange={(newValue: IReactSelectOption, actionMeta) => {
                    handleChange(actionMeta.name, newValue.value);
                }}
                onBlur={(e) => handleBlur(e)}
                isDisabled={disabled}
                value={value}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused ? '#0d6efd' : (hasError ? 'red' : '#dee2e6'),
                    }),
                }}
            />
            {hasError ? <div id='asas' className="invalid-feedback"> {errorMsg} </div> : ''}
        </>
    );
}

export default SelectWithSearch;
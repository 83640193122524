import React, { FC } from 'react';
import { AiOutlineAlert } from "react-icons/ai";
import { BsInfoCircle } from "react-icons/bs";
import { GrStatusGood } from "react-icons/gr";
import { MdOutlineErrorOutline } from "react-icons/md";
import { HiBellAlert } from "react-icons/hi2";
import { AlertColors } from '../../types/colors/alertColors.type';
import CloseBtn from './CloseBtn';

type TypeAlert = {
    type: AlertColors, 
    message: string | React.ReactNode, 
    isShow: boolean, 
    iconStart?: React.ReactNode | null, 
    onClose?: () => void, 
    subject?: string
}

const Alert: FC<TypeAlert> = ({ 
    type, 
    message, 
    isShow, 
    iconStart = null, 
    onClose = null, 
    subject = '', 
}) => {

    if (!isShow) {
        return null;
    }

    let classes = '';
    let iconStartSpan: string | React.ReactNode = '';

    classes = getFullBootstarpClass(type);
    iconStartSpan = getDefaultIconByClass(type);

    if (iconStart != null) iconStartSpan = <span>{iconStart}</span>;

    return (
        <>
            {subject ? (
                <div className={classes} role="alert">
                    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '1.2rem' }}>
                        <div style={{
                            display: 'flex', justifyContent: 'space-between',
                            alignItems: 'center', gap: '0.5rem',
                        }}>
                            {iconStartSpan}
                            <span style={{ display: 'inline', fontSize: '1.2rem', fontWeight: '500' }}>{subject}</span>
                        </div>
                        <div>
                            {onClose && <CloseBtn onClick={onClose} />}
                        </div>
                    </div>
                    <div style={{ fontSize: '1.1rem' }}>
                        {message ? message : ''}
                    </div>
                </div>
            ) : ''}
            {!subject ? (
                <div className={classes} role="alert">
                    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '1.2rem' }} >
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '0.5rem' }}>
                            {iconStartSpan}
                            <span style={{ display: 'inline', fontSize: '1.1rem' }}>{message}</span>
                        </div>
                        {onClose && <CloseBtn onClick={onClose} />}
                    </div>
                </div>
            ) : ''}
        </>
    );

    function getDefaultIconByClass(type: AlertColors) {
    
        if(type === 'secondary') return <HiBellAlert size={27} />;
        if(type === 'success') return <GrStatusGood size={27} />;
        if(type === 'danger') return <MdOutlineErrorOutline size={27} />;
        if(type === 'warning') return <AiOutlineAlert size={27} />;
        if(type === 'info') return <BsInfoCircle size={27} />;
        if(type === 'light') return <HiBellAlert size={27} />;
        if(type === 'dark') return <HiBellAlert size={27} />;
    
        return null;
    }

    function getFullBootstarpClass(type: AlertColors) {
        let classes = "alert alert-primary";
    
        if(type === AlertColors.SECONDARY) classes = "alert alert-secondary";
        if(type === AlertColors.SUCCESS) classes = "alert alert-success";
        if(type === AlertColors.DANGER) classes = "alert alert-danger";
        if(type === AlertColors.WARNING) classes = "alert alert-warning";
        if(type === AlertColors.INFO) classes = "alert alert-info";
        if(type === AlertColors.LIGHT) classes = "alert alert-light";
        if(type === AlertColors.DARK) classes = "alert alert-dark";
    
        return classes;
    }
}

export default Alert;